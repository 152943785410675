







































import { Component, Vue } from 'vue-property-decorator'
import AonButton from '@/components/AonButton.vue'
import constants from '@/constants'
import { Shift } from '@/services/Shift'
import { Action, Getter } from 'vuex-class'
import { ACTION_SET_NEW_EMAIL } from '@/store/modules/user/actionTypes'
import { ACTION_RESET_PRODUCT_SERIALIZED_CONFIG, ACTION_RESET_SALE_JOURNEY_STATE } from '@/store/modules/product/actionTypes'

@Component({
  components: {
    AonButton
  }
})
export default class Dashboard extends Vue {
  @Getter('app/deactivateAddNewDevice') deactivateAddNewDevice!: boolean
  @Getter('app/deactivateUpdatePayment') deactivateUpdatePayment!: boolean
  @Action(ACTION_SET_NEW_EMAIL) setNewEmail!: (email: string) => void

  gotToPolicy () {
    this.$router.push({ name: constants.routeNames.POLICY_LIST })
  }

  async goToApplication() {
    const canAddDevice = await Shift.canAddAdditionalDevice()
    if (canAddDevice) {
      await this.$router.push({
        name: constants.routeNames.BEFORE_APPLICATION
      })
    } else {
      const msg = this.$t('common.error.deviceLimitReached').toString()
      this.$showErrorPopUp(msg)
    }
  }

  viewClaim () {
    this.$router.push({ name: constants.routeNames.VIEW_CLAIM_LIST })
  }

  contactUs() {
    this.$router.push({ name: constants.routeNames.CONTACT_US })
  }

  updateMyInfo() {
    this.setNewEmail('')
    this.$router.push({ name: constants.routeNames.UPDATE_MY_INFO })
  }

  async updatePaymentDetails() {
    const latestInvoice = await Shift.getLatestInvoice()
    if (latestInvoice) {
      await this.$router.push({ name: constants.routeNames.APPLICATION_PAYMENT, query: { detailsUpdate: 'true', invoiceId: latestInvoice.id } })
    } else {
      this.$showErrorPopUp(this.$t('payment.error.purchaseRequired').toString())
    }
  }

  mounted() {
    this.$store.dispatch(ACTION_RESET_SALE_JOURNEY_STATE)
    this.$store.dispatch(ACTION_RESET_PRODUCT_SERIALIZED_CONFIG)
  }
}
